// ./src/App.tsx

import React, { useEffect, useState } from "react";
import uploadFileToBlob, { getBlobsInContainer } from "../azure-storage-blob";
import { Container } from "@material-ui/core";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { List, ListItem, ListItemText, ListItemButton, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import LoadingButton from "@mui/lab/LoadingButton";

import DeleteIcon from "@mui/icons-material/Delete";
import { Blob } from "../azure-storage-blob";

const UploadImage = (): JSX.Element => {
  const fileInput = document.getElementById("file-input");
  const { instance } = useMsal();

  // all blobs in container
  const [blobList, setBlobList] = useState<Array<Blob>>([]);

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<FileList[]>([]);
  const [alert, setAlert] = useState<any>("success");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const alertTypes = (type: any) => {
    if (type === "success") {
      return (
        <Alert severity='success'>
          <AlertTitle>Success</AlertTitle>
          All items were uploaded successfuly
        </Alert>
      );
    } else if (type === "error") {
      return (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          Opps, something went wrong. Please try later
        </Alert>
      );
    } else if (type === "warning") {
      return (
        <Alert severity='warning'>
          <AlertTitle>Warning</AlertTitle>A file with that name already exists
        </Alert>
      );
    } else if (type === "selected") {
      return (
        <Alert severity='warning'>
          <AlertTitle>Warning</AlertTitle>You have already selected this file
        </Alert>
      );
    }
  };

  // UI/form management
  const [uploading, setUploading] = useState(false);

  const onFileChange = (event: any) => {
    if (!event.target.files[0]) return;
    const files = fileSelected;
    const newFile = event.target.files[0];
    const alreadySelected = fileSelected.findIndex((file) => file[0].name === newFile.name);
    const alreadyUploaded = blobList.findIndex((file) => file.name === newFile.name);
    if (alreadySelected === -1 && alreadyUploaded === -1) {
      files.push(event.target.files);
      setFileSelected([...files]);
    } else if (alreadySelected !== -1) {
      setAlert("selected");
      setShowAlert(true);
    } else if (alreadyUploaded !== -1) {
      setAlert("warning");
      setShowAlert(true);
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getContainerItems = async () => {
    const response = await getBlobsInContainer();
    setBlobList(response.blobs);
    setError(response.isError);
    setErrorMsg(response.errorMsg);
  };

  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer: any = await uploadFileToBlob(fileSelected);
    // prepare UI for results
    if (blobsInContainer) {
      setShowAlert(true);
      setAlert("success");
      setBlobList(blobsInContainer.blobs);
    } else {
      setShowAlert(true);
      setAlert("error");
    }
    // reset state/form
    setFileSelected([]);
    setUploading(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const deleteFile = (index: number) => {
    const files = fileSelected;
    files.splice(index, 1);
    setFileSelected([...files]);
  };

  const DisplayForm = () => (
    <div>
      <Stack spacing={2} direction='row' alignItems='center'>
        <h2>Selected files:</h2>
        <div>
          <Button id='select-button' onClick={() => fileInput?.click()} variant='contained'>
            {fileSelected.length === 0 ? "Select files" : "Select Another File"}
          </Button>
          <input
            type='file'
            id='file-input'
            multiple
            style={{ display: "none" }}
            onChange={onFileChange}
          />
        </div>
      </Stack>

      {fileSelected.length > 0 ? (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 600,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {fileSelected.map((file, index) => {
            return (
              <ListItem
                key={file[0].name}
                disablePadding
                component='a'
                style={{ color: "black" }}
                secondaryAction={
                  !uploading ? (
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={(event) => {
                        event.preventDefault();
                        console.log("deleted");
                        deleteFile(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <div id={`progress-${file[0].name}`} />
                  )
                }
              >
                <ListItemButton>
                  <ListItemText primary={file[0].name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <p>No items selected</p>
      )}
      <Box textAlign='center'>
        <LoadingButton
          loading={uploading}
          variant='contained'
          type='submit'
          onClick={onFileUpload}
          disabled={!(fileSelected && fileSelected.length > 0)}
          color='primary'
          style={{ width: "30%", marginTop: "40px" }}
        >
          Upload!
        </LoadingButton>
      </Box>
    </div>
  );

  // display file name and image
  const DisplayImagesFromContainer = () => (
    <div>
      <h2>Uploaded items</h2>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          maxHeight: 600,
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {blobList.map((item) => {
          return (
            <ListItem
              key={item.name}
              disablePadding
              component='a'
              href={`${item.url}`}
              style={{ color: "black" }}
              // secondaryAction={
              //   <IconButton
              //     edge='end'
              //     aria-label='delete'
              //     onClick={(event) => {
              //       event.preventDefault();
              //       console.log("deleted");
              //       deleteBlob(item.name);
              //     }}
              //   >
              //     <DeleteIcon />
              //   </IconButton>
              // }
            >
              <ListItemButton>
                <ListItemText primary={item.name} secondary={item.lastModified} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  useEffect(() => {
    getContainerItems();
  }, []);

  const modalError = (
    <Modal
      open={isError}
      keepMounted
      onClose={() => setError(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          boxShadow: "14px",
          padding: "20px",
        }}
      >
        <Typography id='modal-modal-title' variant='h6' component='h2' color='red'>
          Error
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 1 }}>
          {errorMsg}
        </Typography>
      </Box>
    </Modal>
  );

  return (
    <Container maxWidth='md' style={{ marginTop: "1%" }}>
      {isError && modalError}
      {showAlert && alertTypes(alert)}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ marginRight: "20px" }}>
          <b>Hello</b> {localStorage.getItem("userName")}!
        </p>
        <Button
          variant='outlined'
          onClick={() => {
            instance.logoutRedirect({
              // postLogoutRedirectUri: "http://localhost:3000/login",
              postLogoutRedirectUri: "https://jpeuploads.loyaltylogistix.com",
            });
            localStorage.clear();
          }}
        >
          Logout
        </Button>
      </div>
      <h1 style={{ textAlign: "center", margin: "30px" }}>Upload files</h1>
      {DisplayForm()}
      <hr />
      {blobList.length > 0 && DisplayImagesFromContainer()}
    </Container>
  );
};

export default UploadImage;
