import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { createBrowserHistory } from "history";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Login from "./pages/Login";
import ProtectedRoute from "../src/Auth/ProtectedRoutes";
import { protectedResources } from "../src/Auth/auth.config";
import UploadImage from "./pages/UploadImage";

const history = createBrowserHistory();

function App() {
  
  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event:any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          localStorage.setItem("accountId", event?.payload?.uniqueId);
          localStorage.setItem("userName", event?.payload?.account.name)
          history.push(`${history.location.pathname}/upload-file`);
        }
      }
    });
    
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  // useEffect(() => {
  //   if (accounts.length > 0) localStorage.setItem("userName", accounts?.[0].name);
  // }, [accounts]);

  useEffect(() => {
    if (accounts.length > 0 && inProgress === "none") {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.loginApi.scopes,
          account: accounts?.[0],
        })
        .then((response) => {
          // Set the access token in the localStorage to be used
          // later
          localStorage.setItem("token", response?.accessToken);
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (accounts.length > 0 && inProgress === "none") {
              instance
                .acquireTokenRedirect({
                  scopes: protectedResources.loginApi.scopes,
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [inProgress, instance, accounts]);

  const routes = [
   
    {
      path:"/upload-file",
      component: <UploadImage/>
    },
   
  ]
  
  return inProgress !== "none" ? (
    <Box sx={{ display: "flex", justifyContent:"center", alignItems:"center", marginTop:"50%" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Router>
        {routes.map((route, index) => (
          <React.Fragment key={index}>
            <ProtectedRoute route={route} />
          </React.Fragment>
        ))}
      <Route  path='/'>
        {isAuthenticated ? (
          <Redirect to='/upload-file' />
          ) : (
            <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        )}
      </Route>
        
  </Router>
  );
}
export default App;
