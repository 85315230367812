// import AppContext from "../contexts/AppContext";
import { Redirect, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export default function ProtectedRoute({ route }) {
  // The useIsAuthenticated hook returns a boolean indicating whether or not an account is signed in.
  // It optionally accepts an accountIdentifier object you can provide if you need to know whether or
  // not a specific account is signed in.
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      path={route.path}
      render={() => (isAuthenticated ? route.component : <Redirect to='/' />)}
    />
  );
}
