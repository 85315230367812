import { InteractiveBrowserCredential } from "@azure/identity";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { setLogLevel, AzureLogger } from "@azure/logger";
localStorage.removeItem("error");
setLogLevel("info");
AzureLogger.log = (...args) => {
  console.log("azureLogger", args);
  localStorage.setItem(
    "error",
    args[0]
      .split("message:")
      .pop()
      .split(".")[0]
  );
};
const containerName = `uploads`;
// const sasToken =
//   "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwlacupitfx&se=2024-01-01T17:10:29Z&st=2021-12-03T09:10:29Z&spr=https&sig=Ciqa4tyekald8udoGlE5%2BT%2FzbOjTe%2FKb%2FttdBj0dXro%3D";
const storageAccountName = "vwagfilesync";
const clientId = "c6b7dd7c-30f3-41a6-a090-03e9ded71755";
const tenantId = "3a13b303-7732-4843-bf8e-0f8dd7fe3a9a";
const defaultAzureCredential = new InteractiveBrowserCredential({
  clientId,
  tenantId,
});
export interface Blob {
  name: string;
  url: string;
  lastModified: string;
}

const blobService = new BlobServiceClient(
  `https://${storageAccountName}.blob.core.windows.net`,
  defaultAzureCredential
);
const containerClient: ContainerClient = blobService.getContainerClient(containerName);

// get blobs list in container
export const getBlobsInContainer = async () => {
  // eslint-disable-next-line
  const returnedBlobUrls: any = [];
  let errorMsg = "";
  let isError = false;
  try {
    for await (const blob of containerClient.listBlobsFlat()) {
      console.log("from getBlobsInContainer", blob);
      console.log({ blob });
      returnedBlobUrls.push({
        url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`,
        name: blob.name,
        lastModified: blob.properties.lastModified.toDateString(),
      });
    }
  } catch (error) {
    isError = true;
    if (error?.statusCode === 403) {
      errorMsg = "This account doesn't have access to the resources please contact the admin";
    } else {
      errorMsg = "Oppps... Something went wrong. Please try later";
    }
  }
  return {
    blobs: returnedBlobUrls,
    isError,
    errorMsg,
  };
};

const createBlobInContainer = async (containerClient: ContainerClient, files: FileList[]) => {
  try {
    const promises = [];
    for (const file of files) {
      const blobClient = containerClient.getBlockBlobClient(file[0].name);
      const options = {
        blobHTTPHeaders: { blobContentType: file[0].type },
        onProgress: (ev: any) => {
          console.log({ ev });
          var percentdone = (ev.loadedBytes / file[0].size) * 100;
          const progressItem: HTMLElement | null = document.getElementById(
            `progress-${file[0].name}`
          );
          if (progressItem) {
            console.log(progressItem);
            progressItem.innerHTML = `${Math.round(percentdone)}%`;
          }
          console.log(percentdone.toFixed(2) + "%");
        },
      };

      promises.push(blobClient.uploadData(file[0], options));
    }
    await Promise.all(promises);
  } catch (error) {
    console.log(error);
    return "error";
  }
};

// [Browsers only] A helper method used to convert a browser Blob into string.
async function blobToString(blob: any) {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev: any) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}

export const downloadFile = async (blobName: string) => {
  const blobClient = containerClient.getBlobClient(blobName);

  // Get blob content from position 0 to the end
  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  const downloadBlockBlobResponse = await blobClient.download();
  const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
  console.log("Downloaded blob content", downloaded);
};

const uploadFileToBlob = async (files: FileList[] | null): Promise<object> => {
  if (!files) return [];
  // upload file
  await createBlobInContainer(containerClient, files);

  // get list of blobs in container
  return getBlobsInContainer();
};

// export const deleteBlob = async (blobName: string) => {

//   const containerClient: ContainerClient = blobService.getContainerClient(containerName);

//   const blockBlobClient = containerClient.getBlockBlobClient(blobName);
//   await blockBlobClient.download(0);
//    await blockBlobClient.delete();
// };

export default uploadFileToBlob;
